import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { registerEvent } from "~/analytics";
import "./ph-response.css";

import * as ga from "~/contants/gaConstants";
import {
  RightOutlined,
  SettingOutlined,
  UserOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import style from "./MainLayout.module.css";
import { default as DashboardIcon } from "~/component/imt-icon/Dashboard";
import { default as InvoiceIcon } from "~/component/imt-icon/Invoice";
import { default as ExpenseIcon } from "~/component/imt-icon/Expense";
import { default as MasterIcon } from "~/component/imt-icon/Master";
import { default as PaymentIcon } from "~/component/imt-icon/Payment";
import { default as EmployeeIcon } from "~/component/imt-icon/Employee";
import { default as PayrollIcon } from "~/component/imt-icon/Payroll";
import { default as ReimbursementIcon } from "~/component/imt-icon/Reimbursement";
import { default as ComplianceIcon } from "~/component/imt-icon/Compliance";
import { default as SettingIcon } from "~/component/imt-icon/Setting";

import { default as SalesIn } from "~/component/imt-icon/SalesInvoice";
import SalesCredit from "~/component/imt-icon/SalesCredit";
import SalesDebit from "~/component/imt-icon/SalesDebit";
import { default as purEx } from "~/component/imt-icon/NorEx";
import Selldb from "~/component/imt-icon/Selldb";
import Purdb from "~/component/imt-icon/Purdb";
import { default as VendorIcon } from "~/component/imt-icon/VendorNew";
import { default as proMasIcon } from "~/component/imt-icon/ProductMan";
import { default as expCatIcon } from "~/component/imt-icon/ExpenseCat";
import { string } from "random-js";
import { LoginRole } from "~/api/auth";
type ComponentProps = {
  isActive: boolean;
  closeButton: React.ReactNode;
  onItemClick: () => void;
  openCompanyList: () => void;
  openAddClient: () => void;
  openForgetPass: () => void;
  roleChange: () => void;
  sync: () => void;
  logout: () => void;
};

const PhoneNav: React.FC<ComponentProps> = ({
  isActive,
  onItemClick,
  closeButton,
  openCompanyList,
  openAddClient,
  openForgetPass,
  roleChange,
  sync,
  logout,
}) => {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const userData = localStorage.getItem("imt__user");
  const userParseData = JSON.parse(userData!);
  const [menu, setMenu] = useState<Array<any>>([
    {
      key: "Account",
      icon: (
        <UserOutlined style={{ fontSize: "20px", fontWeight: "inherit" }} />
      ),
      link: "",
      showSubmenu: false,
      access: [1, 2, 3, 4, 5],
      disableFor: [],
      selectedSubMenuIdx: -1,
      subMenu: [
        {
          key: "Company List",
          icon: (
            <UserOutlined style={{ fontSize: "25px", fontWeight: "inherit" }} />
          ),
          link: "",
          openModel: openCompanyList,
          showSubmenu: false,
          subMenu: [],
          access: [1, 3, 5, 2],
          disableFor: [],
        },
        {
          key: "Add Client",
          icon: (
            <UserOutlined style={{ fontSize: "25px", fontWeight: "inherit" }} />
          ),
          link: "",
          openModel: openAddClient,
          showSubmenu: false,
          subMenu: [],
          access: [1, 3, 5],
          disableFor: [3, 5],
        },
        {
          key: "Profile",
          icon: (
            <UserOutlined style={{ fontSize: "25px", fontWeight: "inherit" }} />
          ),
          link: "/app/profile",
          openModel: undefined,
          showSubmenu: false,
          subMenu: [],
          access: [1, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
        {
          key: "Forget Password",
          icon: (
            <UserOutlined style={{ fontSize: "25px", fontWeight: "inherit" }} />
          ),
          link: "",
          openModel: openForgetPass,
          showSubmenu: false,
          subMenu: [],
          access: [1, 3, 5, 2],
          disableFor: [],
        },
        {
          key: "Membership",
          icon: (
            <UserOutlined style={{ fontSize: "25px", fontWeight: "inherit" }} />
          ),
          link: "/app/settings-membership",
          openModel: undefined,
          showSubmenu: false,
          subMenu: [],
          access: [1],
          disableFor: [1, 2, 3, 4, 5],
        },
        {
          key:
            userParseData.activeRole === LoginRole.ADMIN
              ? "Admin"
              : userParseData.activeRole === LoginRole.EMPLOYEE
              ? "Employee"
              : userParseData.activeRole === LoginRole.CA
              ? "CA"
              : userParseData.activeRole === LoginRole.DELIGATE
              ? "Delegate user"
              : "Superuser",
          icon: (
            <UserOutlined style={{ fontSize: "25px", fontWeight: "inherit" }} />
          ),
          link: "",
          openModel: roleChange,
          showSubmenu: false,
          subMenu: [],
          access: [1, 2, 3, 4, 5],
          disableFor: [],
        },
        {
          key: "Log Out",
          icon: (
            <UserOutlined style={{ fontSize: "25px", fontWeight: "inherit" }} />
          ),
          link: "",
          openModel: logout,
          showSubmenu: false,
          subMenu: [],
          access: [1, 2, 3, 4, 5],
          disableFor: [],
        },
      ],
    },
    {
      key: "Overview",
      icon: <DashboardIcon style={{ height: "20px" }} />,
      link: userParseData?.plan_purchased
        ? "/app/dashboard"
        : "/app/expiredMembership",
      showSubmenu: false,
      openModel: undefined,
      selectedSubMenuIdx: -1,
      subMenu: [],
      access: [1, 3, 5],
      disableFor: [],
    },
    {
      key: "Sales",
      icon: <InvoiceIcon style={{ height: "20px" }} />,
      link: "",
      showSubmenu: false,
      openModel: undefined,
      access: [1, 3, 5],
      disableFor: [1, 2, 3, 4, 5],
      selectedSubMenuIdx: -1,
      subMenu: [
        {
          key: "Invoice",
          icon: (
            <SalesIn
              style={{ width: "10px", height: "10px", fontSize: "10px" }}
            />
          ),
          openModel: undefined,
          link: userParseData?.plan_purchased
            ? "/app/invoice"
            : "/app/expiredMembership",
          showSubmenu: false,
          subMenu: [],
          access: [1, 3, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
        {
          key: "Credit note",
          openModel: undefined,
          icon: (
            <SalesCredit
              style={{ width: "10px", height: "10px", fontSize: "10px" }}
            />
          ),
          link: userParseData?.plan_purchased
            ? "/app/invoice/creditNote"
            : "/app/expiredMembership",
          showSubmenu: false,
          subMenu: [],
          access: [1, 3, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
        {
          key: "Debit note",
          openModel: undefined,
          icon: (
            <SalesDebit
              style={{ width: "10px", height: "10px", fontSize: "10px" }}
            />
          ),
          link: userParseData?.plan_purchased
            ? "/app/expense/sellerdebitNote"
            : "/app/expiredMembership",
          showSubmenu: false,
          subMenu: [],
          access: [1, 3, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
      ],
    },
    {
      key: "Purchase",
      icon: <ExpenseIcon style={{ height: "20px" }} />,
      link: "",
      showSubmenu: false,
      openModel: undefined,
      access: [1, 3, 5],
      disableFor: [1, 2, 3, 4, 5],
      selectedSubMenuIdx: -1,
      subMenu: [
        {
          key: "Expense",
          icon: <span className={style.subMenuInvoiceIcon}>{purEx}</span>,
          link: userParseData?.plan_purchased
            ? "/app/expense"
            : "/app/expiredMembership",
          showSubmenu: false,
          openModel: undefined,
          subMenu: [],
          access: [1, 3, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
        {
          key: "Supplier credit note",
          icon: (
            <Selldb
              style={{ width: "10px", height: "10px", fontSize: "10px" }}
            />
          ),
          link: userParseData?.plan_purchased
            ? "/app/expense/purchasedebitNote"
            : "/app/expiredMembership",
          showSubmenu: false,
          openModel: undefined,
          subMenu: [],
          access: [1, 3, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
        {
          key: "Supplier debit note",
          icon: (
            <Purdb
              style={{ width: "10px", height: "10px", fontSize: "10px" }}
            />
          ),
          link: userParseData?.plan_purchased
            ? "/app/expense/sellerdebitNote"
            : "/app/expiredMembership",
          showSubmenu: false,
          openModel: undefined,
          subMenu: [],
          access: [1, 3, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
      ],
    },
    {
      key: "Masters",
      icon: (
        <span style={{ marginLeft: "-2px" }}>
          <MasterIcon height={"20px"} width={"20px"} />
        </span>
      ),
      link: "",
      showSubmenu: false,
      access: [1, 3, 5],
      disableFor: [1, 2, 3, 4, 5],
      openModel: undefined,
      selectedSubMenuIdx: -1,
      subMenu: [
        {
          key: "Vendor / Customer",
          icon: VendorIcon,
          link: userParseData?.plan_purchased
            ? "/app/masters/vendor"
            : "/app/expiredMembership",
          showSubmenu: false,
          subMenu: [],
          openModel: undefined,
          access: [1, 3, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
        {
          key: "Products",
          icon: proMasIcon,
          link: userParseData?.plan_purchased
            ? "/app/masters/productMaster"
            : "/app/expiredMembership",
          showSubmenu: false,
          openModel: undefined,
          subMenu: [],
          access: [1, 3, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
        {
          key: "Expense categories",
          icon: expCatIcon,
          link: userParseData?.plan_purchased
            ? "/app/masters/expenseCategory"
            : "/app/expiredMembership",
          showSubmenu: false,
          openModel: undefined,
          subMenu: [],
          access: [1, 3, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
      ],
    },
    {
      key: "Payment",
      icon: (
        <span>
          <PaymentIcon height={20} />
        </span>
      ),
      link: userParseData?.plan_purchased
        ? "/app/payment"
        : "/app/expiredMembership",
      showSubmenu: false,
      subMenu: [],
      openModel: undefined,
      selectedSubMenuIdx: -1,
      access: [1, 3, 5],
      disableFor: [1, 2, 3, 4, 5],
    },
    {
      key:
        userParseData.activeRole === LoginRole.EMPLOYEE
          ? "Profile"
          : "Employee",
      icon: <EmployeeIcon style={{ height: "20px" }} />,
      link: userParseData?.plan_purchased
        ? "/app/employee"
        : "/app/expiredMembership",
      showSubmenu: false,
      subMenu: [],
      openModel: undefined,
      selectedSubMenuIdx: -1,
      access: [1, 3, 5, 2],
      disableFor: [2, 3, 4, 5, 1],
    },
    {
      key: "Payroll",
      icon: <PayrollIcon style={{ height: "23px" }} />,
      link: userParseData?.plan_purchased
        ? "/app/payroll"
        : "/app/expiredMembership",
      showSubmenu: false,
      subMenu: [],
      openModel: undefined,
      access: [1, 3, 5, 2],
      selectedSubMenuIdx: -1,
      disableFor: [1, 2, 3, 4, 5],
    },
    {
      key: "Reimbursement",
      icon: <ReimbursementIcon style={{ height: "20px" }} />,
      link: userParseData?.plan_purchased
        ? "/app/reimbursement"
        : "/app/expiredMembership",
      disableFor: [1, 2, 3, 4, 5],
      showSubmenu: false,
      subMenu: [],
      openModel: undefined,
      access: [1, 3, 5, 2],
      selectedSubMenuIdx: -1,
    },
    {
      key: "Compliance",
      icon: <ComplianceIcon style={{ height: "20px" }} />,
      link: userParseData?.plan_purchased
        ? "/app/compliance-new"
        : "/app/expiredMembership",
      showSubmenu: false,
      subMenu: [],
      openModel: undefined,
      access: [1, 3, 5],
      disableFor: [1, 2, 3, 4, 5],
      selectedSubMenuIdx: -1,
    },
    {
      key: "Settings",
      icon: <SettingOutlined style={{ fontSize: "20px" }} />,
      link: "",
      showSubmenu: false,
      openModel: undefined,
      access: [1, 5, 3],
      disableFor: [1, 2, 3, 4, 5],
      selectedSubMenuIdx: -1,
      subMenu: [
        {
          key: "Role",
          icon: <ComplianceIcon style={{ height: "25px", width: "25px" }} />,
          link: userParseData?.plan_purchased
            ? "/app/settings-role"
            : "/app/expiredMembership",
          showSubmenu: false,
          subMenu: [],
          openModel: undefined,
          access: [1, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
        {
          key: "Users",
          icon: <ComplianceIcon style={{ height: "25px", width: "25px" }} />,
          link: userParseData?.plan_purchased
            ? "/app/settings-users"
            : "/app/expiredMembership",
          showSubmenu: false,
          subMenu: [],
          openModel: undefined,
          access: [1, 5],
          disableFor: [1, 2, 3, 4, 5],
        },
      ],
    },
    {
      key: "Sync",
      icon: <SyncOutlined style={{ fontSize: "18px" }} />,
      link: "",
      showSubmenu: false,
      subMenu: [],
      openModel: sync,
      access: [1, 5, 3],
      disableFor: [1, 2, 3, 4, 5],
      selectedSubMenuIdx: -1,
    },
  ]);
  const colors = [
    "#0b74d6",
    "#d60b3e",
    "#d6cf0b",
    "#d60b7b",
    "#d60b52",
    "#0b0ed6",
  ];
  const history = useHistory();
  const toggleSubmenu = (idx: number) => {
    setMenu((prevMenu) =>
      prevMenu.map((item, index) =>
        index === idx ? { ...item, showSubmenu: !item.showSubmenu } : item
      )
    );
  };
  const changeSubMenuPosion = (perentIdx: number, subIdx: number) => {
    setMenu((prevMenu) =>
      prevMenu.map((item, index) =>
        index === perentIdx
          ? { ...item, selectedSubMenuIdx: subIdx }
          : { ...item, selectedSubMenuIdx: -1 }
      )
    );
  };
  const createSubNav = (data: any, perentIdx: number) => {
    const ele = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i]?.access.includes(userParseData.activeRole)) {
        ele.push(
          <div
            key={data[i].key}
            style={{
              padding: "3px 10px",
              display: "flex",
              margin: "0px",
              alignContent: "center",
              alignItems: "center",
              borderLeft:
                menu[perentIdx].selectedSubMenuIdx === i
                  ? "2px solid #868c9b"
                  : "1px solid #dbd7d7",
              filter: data[i]?.disableFor.includes(userParseData.activeRole)
                ? "opacity(50%)"
                : "opacity(100%)",
            }}
            onClick={() => {
              if (data[i]?.disableFor.includes(userParseData.activeRole)) {
                return;
              }
              handleModals(data[i].link, data[i].openModel);
              changeSubMenuPosion(perentIdx, i);
            }}
          >
            <div
              style={{
                width: "200px",
                padding:
                  menu[perentIdx].selectedSubMenuIdx === i
                    ? "10px 0px"
                    : "5px 0px",
                backgroundColor:
                  menu[perentIdx].selectedSubMenuIdx === i
                    ? "#f4f7ff"
                    : "transparent",
                borderRadius: "10px",
                fontWeight:
                  menu[perentIdx].selectedSubMenuIdx === i
                    ? "bolder"
                    : "bolder",
                color:
                  menu[perentIdx].selectedSubMenuIdx === i
                    ? "black"
                    : "rgb(92 91 88)",
              }}
            >
              <span style={{ margin: "0px 10px" }}>
                <svg height="10" width="10" xmlns="http://www.w3.org/2000/svg">
                  <circle
                    r="2"
                    cx="2"
                    cy="5"
                    fill={colors[Math.floor(Math.random() * 6)]}
                  />
                </svg>
              </span>
              {data[i].key}
            </div>
          </div>
        );
      }
    }

    return ele;
  };

  const handlePageChange = (link: string) => {
    onItemClick();
    registerEvent(
      ga.EVENT_CATEGORY_BUTTON_CLICK,
      ga.EVENT_CLICK,
      ga.events.mainLayout315
    );
    history.push(link);
    setMenu((prevMenu) =>
      prevMenu.map((item, index) =>
        index === selectedMenu ? { ...item, showSubmenu: false } : item
      )
    );
  };
  const handleModals = (link: string, openModal: any) => {
    if (link) {
      handlePageChange(link);
    } else {
      onItemClick();
      openModal();
    }
    setMenu((prevMenu) =>
      prevMenu.map((item, index) =>
        index === selectedMenu ? { ...item, showSubmenu: false } : item
      )
    );
  };

  return (
    <>
      {isActive && (
        <div className="ph-menu-bar">
          {closeButton}
          <div
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              background: "#e4ccff",
              marginTop: "20px",
            }}
          />
          <div style={{ marginTop: "5px", fontWeight: "bolder" }}>
            {userParseData?.first_name} {userParseData?.last_name}
          </div>
          <div style={{ marginTop: "3px", fontSize: "11px" }}>
            {userParseData?.company_name}
          </div>
          <div
            style={{ marginTop: "4px", fontSize: "11px", fontWeight: "bold" }}
          >
            {userParseData?.email}
          </div>

          <hr
            style={{
              margin: "10px 0px",
              background: "#0d99ff",
              marginBottom: "20px",
            }}
          />

          <div>
            {menu.map((data, idx) => (
              <>
                {data.access.includes(userParseData.activeRole) ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "15px 0px",
                        filter: data?.disableFor.includes(
                          userParseData.activeRole
                        )
                          ? "opacity(50%)"
                          : "opacity(100%)",
                      }}
                      onClick={() => {
                        if (data.subMenu.length === 0) {
                          if (
                            data.disableFor.includes(userParseData.activeRole)
                          ) {
                            return;
                          }
                          handleModals(data.link, data.openModel);
                          setSelectedMenu(idx);
                          toggleSubmenu(idx);
                          // onItemClick();
                        } else {
                          if (
                            data.disableFor.includes(userParseData.activeRole)
                          ) {
                            return;
                          }
                          setSelectedMenu(idx);
                          toggleSubmenu(idx);
                        }
                      }}
                      key={`${data?.key}${data?.key}`}
                    >
                      <div>{data?.icon}</div>
                      <span
                        style={{
                          paddingLeft: "5px",
                          color:
                            idx === selectedMenu ? "#1956b1" : "rgb(92 91 88)",
                          fontWeight: "bold",
                        }}
                      >
                        {data?.key}
                        <span style={{ marginLeft: "3px" }}>
                          {data.subMenu.length > 0 && (
                            <RightOutlined style={{ fontSize: "8px" }} />
                          )}
                        </span>
                      </span>
                    </div>
                    {data.subMenu.length > 0 &&
                      data.showSubmenu &&
                      idx === selectedMenu && (
                        <div style={{ margin: "5px 11px" }}>
                          {createSubNav(data.subMenu, idx)}
                        </div>
                      )}
                  </>
                ) : null}
              </>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PhoneNav;
