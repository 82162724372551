import React, { Component } from "react";
import { Select } from "antd";
import getYear from "date-fns/fp/getYear";
import { subYears } from "date-fns/esm";
import { addYears } from "date-fns";

interface Props {
  selectedFy: any;
  onFyChange: (value: any) => void;
  style?: any;
  isCompanyList?: boolean;
  isVenTn?: boolean;
}

interface State {
  opts_fy: Array<any>;
}

export default class FySelect extends Component<Props, State> {
  state: State = {
    opts_fy: [],
  };
  async componentDidMount() {
    const year = new Date().getFullYear();
    const current = new Date("2025-01-01");
    const eligbleDate =
      current.getTime() <= new Date(`${year}-04-01`).getTime() &&
      current.getTime() > new Date(`${year - 1}-04-01`).getTime();
    // console.log(current.getTime(),'eligbleDate')
    const nxtYear = getYear(addYears(current, 1));
    const nowYear = getYear(current);
    const prevYear = getYear(subYears(current, 1));
    const prev2Year = getYear(subYears(current, 2));
    const prev3Year = getYear(subYears(current, 3));
    const prev4Year = getYear(subYears(current, 4));
    // console.log(nowYear)
    const years: Array<any> = [];
    for (let i = 2020; i < nowYear + 1; i++) {
      // console.log(`${i}-${i+1}`);
      years.push({
        value: `${i}-${i + 1}`,
        label: `FY ${i}-${i + 1}`,
      });
    }
    this.setState({ opts_fy: years });
    // this.setState({
    //   opts_fy: [
    //     {
    //       value: eligbleDate
    //         ? `${prev4Year}-${prev3Year}`
    //         : `${prev3Year}-${prev2Year}`,
    //       label: eligbleDate
    //         ? `FY ${prev4Year}-${prev3Year}`
    //         : `FY ${prev3Year}-${prev2Year}`,
    //     },
    //     {
    //       value: eligbleDate
    //         ? `${prev3Year}-${prev2Year}`
    //         : `${prev2Year}-${prevYear}`,
    //       label: eligbleDate
    //         ? `FY ${prev3Year}-${prev2Year}`
    //         : `FY ${prev2Year}-${prevYear}`,
    //     },
    //     {
    //       value: eligbleDate
    //         ? `${prev2Year}-${prevYear}`
    //         : `${prevYear}-${getYear(current)}`,
    //       label: eligbleDate
    //         ? `FY ${prev2Year}-${prevYear}`
    //         : `FY ${prevYear}-${getYear(current)}`,
    //     },
    //     {
    //       value: eligbleDate
    //         ? `${prevYear}-${nowYear}`
    //         : `${nowYear}-${nxtYear}`,
    //       label: eligbleDate
    //         ? ` FY ${prevYear}-${nowYear}`
    //         : `FY ${nowYear}-${nxtYear}`,
    //     },
    //     {
    //       value: eligbleDate
    //         ? `${prevYear + 1}-${nowYear + 1}`
    //         : `${nowYear + 1}-${nxtYear + 1}`,
    //       label: eligbleDate
    //         ? ` FY ${prevYear + 1}-${nowYear + 1}`
    //         : `FY ${nowYear + 1}-${nxtYear + 1}`,
    //     },
    //   ],
    // });
  }
  handleFy = (value: any) => {
    this.props.onFyChange(value);
  };

  render() {
    return (
      <>
        <Select
          suffixIcon={
            <svg
              width="10"
              height="8"
              viewBox="0 0 20 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 16L19.5263 0.25H0.473721L10 16Z" fill="#4F4F4F" />
            </svg>
          }
          style={{
            // marginRight: "28em",
            // marginTop: "30px",
            width: "170px",
            color: "black",
            fontWeight: 700,
            maxWidth: "140px",
            ...this.props.style,
          }}
          value={this.props.selectedFy}
          onChange={this.handleFy}
          size={this.props.isCompanyList ? "small" : "middle"}
        >
          {this.state.opts_fy.map((opt) => (
            <>
              {Object.keys(opt).length > 0 ? (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ) : (
                <></>
              )}
            </>
          ))}
        </Select>
      </>
    );
  }
}
